
import {
  LineSeries, Tooltip,
  ChartProvider, XAxis,
} from 'rough-charts';
import { RoughProvider, Circle } from 'react-roughjs';


import { GLOBAL } from '../global';

export default function BarChart({ data, height = GLOBAL.HEIGHT, width = GLOBAL.WIDTH }) {
  const annotationCirclePos = [
    { x: 375, y: 118 },
    { x: 717, y:70 },
    { x: 1024-65, y:60 },
    { x: 1084-70, y:54 },
    { x: 1340-85, y:52 },

  ]
  return (
    <>
     <svg width={window.innerWidth} height={height} style={{ position: 'absolute', left: 0, zIndex:-1 }}>
        <RoughProvider >
          {
            annotationCirclePos.map(datum => {
              return (
              <Circle diameter={40} {...datum} options={{
                strokeWidth: 2
              }}>
              </Circle>
              )
            })
          }
        </RoughProvider>
      </svg>
      <ChartProvider
        height={400}
        data={data}
        margin={{
          bottom: 50,
          left: 20,
          right: 20,
          top: 50
        }}
      >
        <XAxis
          dataKey="date"
          options={{
            stroke: 'transparent'
          }}
        />
        {/* <YAxis /> */}
        <LineSeries
          dataKey="content_count_boy"
          options={{
            stroke: '#08D9D6',
            strokeWidth: 2,
          }}
        />
        <LineSeries
          dataKey="content_count_girl"
          options={{
            stroke: '#FF2E63',
            strokeWidth: 2,
          }}
        />
        <Tooltip height={200} width={320} children={item => {
          const { date, content_count_boy, content_count_girl, content } = item;
          return (
            <>
              {date && <text x={20} y={40}>*date: {date}</text>}
              <text x={20} y={70}>*content_count_boy: {content_count_boy || 0}</text>
              <text x={20} y={100}>*content_count_girl: {content_count_girl || 0}</text>
              <text x={20} y={130}>*content: {content || ''}</text>
            </>
          )
        }} />
      </ChartProvider>
     
    </>
  )
}