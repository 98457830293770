import { useState } from 'react';
import './index.css';
import { GLOBAL } from '../../global';
import * as d3 from 'd3';
import { RoughProvider, Rectangle } from 'react-roughjs';
import Axis from '../axis';
import Grid from '../grid';
import xiaoyun from '../../img/xiaoyun.png';
import xiaoli from '../../img/xiaoli.png';

export default function Timeline({ data, setTooltipProps, width = GLOBAL.WIDTH, height = GLOBAL.HEIGHT }) {

  const cellWidth = 30;
  const cellHeight = 35;
  const timeScale = d3.scaleLinear().domain([0, 24 * 60]).range([0, GLOBAL.WIDTH]);
  const max_content_count = Math.max(...data.map(d => (d['content_count_boy'] + d['content_count_girl'])));

  function timeToMin(time) {
    const hour = time.split(':')[0];
    const min = time.split(':')[1];
    return Number(hour) * 60 + Number(min);
  }

  const initTimeLineProps = [];
  data.forEach((datum, index) => {
    const { time_start, time_end, content_count_boy, content_count_girl } = datum;
    const content_count = content_count_boy + content_count_girl;
    const content_count_rate = content_count / max_content_count;

    const x = timeScale(timeToMin(time_start));
    const end = timeScale(timeToMin(time_end));
    const y = index * (cellHeight - 22);
    const width = end - x;
    const height = cellHeight - 22;
    if (time_start && time_end) {
      initTimeLineProps.push({
        datum,
        x,
        y,
        width,
        height,
        options: {
          fill: d3.interpolateYlOrBr(content_count_rate),
          fillWeight: 3, // thicker lines for hachure
          stroke: GLOBAL.THEME_COLOR,
          strokeWidth: 0,
          hachureGap: 3
        }
      })
    }
  });

  const [timeLineProps, setTimeLineProps] = useState(initTimeLineProps);
  const [imgPos, setImgPos] = useState({ xiaoyun: {x: 0, y: 0}, xiaoli: {x: width-90, y: 0} });

  // 交互：hover样式
  function changeRectStyle(index) {
    const newTimeLineProps = [...initTimeLineProps]
    newTimeLineProps[index] = {
      ...newTimeLineProps[index],
      options: {
        ...newTimeLineProps[index].options,
        stroke: '#000',
        strokeWidth: 2,
        bowing: 0,
      }
    }
    setTimeLineProps(newTimeLineProps);
  }

  function changeImgPos(width, props, index, evt) {
    const { x, y } = props;
    const { offsetX } = evt;
    let pos;
    if(index <= 24) {
      pos = {
        xiaoyun: { x: (index/24)*width/2 - 90, y: y - 40 },
        xiaoli: { x: width - 90 - (index/24)*width/2 + 80 , y: y - 40 }
      }
    } else if(index > 24) {
      // 头像靠右
      if(offsetX < width/2) {
        pos = {
          xiaoyun: { x:  - 90 + offsetX + 110, y: y - 40 },
          xiaoli: { x: - 90+ 80 + offsetX + 110, y: y - 40 }
        }
      } else{
      // 头像靠左
      pos = {
        xiaoyun: { x:  - 90 + offsetX - 110, y: y - 40 },
        xiaoli: { x: - 90+ 80 + offsetX - 110, y: y - 40 }
      }
      }
    }
    setImgPos(pos);
  }

  const hourData = new Array(24).fill(0).map((item, index) => {
    return {
      step: index * 60,
      text: `${index}: 00`
    }
  })

  return (
    <>
    <ul className="innerAnnotation" style={{width: 200, top: 3160, left: 50,}}>
      <li>一开始我们没有怎么聊天哦，看来那时候还属于“试探阶段”😄。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 3285, left: 50,}}>
      <li>到了chinavis之后，我们的聊天记录一下子飙升😳。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 3400, left: 50,}}>
      <li>然后我们就逐渐升温啦🔥，聊天频率逐渐上升。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 3540, left: 50,}}>
      <li>正式告白💓前后拥有了好多“美好回忆”🎆。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 3678, left: 50,}}>
      <li>转正答辩结束后，我们经历了第一次“小分别”。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 3750, left: 50,}}>
      <li>可能是因为每天都很想念对方，所以每天都聊一些些😝。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 3820, left: 50,}}>
      <li>哟...这还没几天呢，做完实验的我又回归啦！</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 3960, left: 50,}}>
      <li>这段时间我们没有怎么微信聊天？原来是我们每天都“腻”在一起啦😏。我们经历了两个小假期：中秋节、国庆节</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 4230, left: 50,}}>
      <li>我们度过了美好的国庆节！然后我依依不舍地回去了惠州、然后飞去北京。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 4330, left: 50,}}>
      <li>那时候我们约定差不多每个月见一次面。每次见面之前，我们每天都用微信、视频聊天的形式想念着对方🥰。</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 4522, left: 50,}}>
      <li>第一次在北京见面了！你看，聊天一下子变少了，因为时间都去玩耍啦🎠</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 4652, left: 50,}}>
      <li>纪念 colorful 第一次发版了！</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 4820, left: 50,}}>
      <li>antv1122品牌日到来之际，也是我们在一起第99天，你送了我11朵闪亮亮的🌹✨；同时小册子也发布了！</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 5058, left: 50,}}>
      <li>开心地迎接第二次来北京的你😄，收到了精致的小项链，很喜欢哦🦄️</li>
    </ul>
    <ul className="innerAnnotation" style={{width: 200, top: 5235, left: 50,}}>
      <li>准备放寒假啦，又可以见面了😊</li>
    </ul>
      <Axis data={hourData} scale={timeScale}></Axis>
      <svg width={width} height={height * 8-70} >
        <Grid data={hourData} scale={timeScale} height={GLOBAL.HEIGHT * 8-70}></Grid>
        <RoughProvider >
          {
            timeLineProps.map((props, index) => {
              if (props) {
                return (
                  <>
                  <Rectangle
                    {...props}
                    onMouseOver={(evt) => {
                      changeImgPos(width, props, index, evt);
                      changeRectStyle(index);
                      setTooltipProps(prev => ({
                        ...prev,
                        width: 300,
                        value: props?.datum,
                        x: evt.pageX,
                        y: evt.pageY,
                        showToolTip: true
                      }))
                    }}
                    onMouseOut={() => {
                      setTimeLineProps(initTimeLineProps);
                      setTooltipProps(prev => ({
                        ...prev,
                        showToolTip: false
                      }))
                    }}
                  ></Rectangle>
                  </>
                )
              }
            })
          }
        </RoughProvider>
        {
          timeLineProps.map((props, index) => {
            if(props.datum['重要节点']) {
              return (
                <text x={width/2} y={props.y} fontSize={12} alignmentBaseline={'hanging'}>
                  {props.datum['重要节点']}
                </text>
              )
            }
            
          })
        }
        
        <image className="avatar" x={imgPos.xiaoyun.x} y={imgPos.xiaoyun.y} width="85" height="100"
          xlinkHref={xiaoyun} />
          <image className="avatar" x={imgPos.xiaoli.x} y={imgPos.xiaoli.y} width="100" height="80"
          xlinkHref={xiaoli} />
      </svg>
    </>
  )
}