import { useState } from 'react';
import { RoughProvider, Rectangle } from 'react-roughjs';
import * as d3 from 'd3';
import { GLOBAL } from '../global';

// 处理绘制日力图元素所需属性
function getRectProps({ data, cellWidth, cellHeight, THEME_COLOR, max_content_count }) {
  return data.map((datum, index) => {
    const { date: d, content_count_boy, content_count_girl, time_start } = datum;

    const content_count = content_count_boy + content_count_girl;
    const content_count_rate = content_count / max_content_count;
    const date = new Date(d);

    // x: 今年的第几周
    const x = (d3.timeWeek.count(d3.timeYear(date), date) - 27) * cellWidth + cellWidth;
    // y: 这周第几天（星期几）星期日为 0，星期一为 1
    const y = (date.getDay()) * cellHeight + cellHeight;

    if (time_start) {
      return {
        datum,
        x,
        y,
        width: cellWidth - 5,
        height: cellWidth - 5,
        options: {
          fill: d3.interpolateYlOrBr(content_count_rate),
          fillWeight: 3, // thicker lines for hachure
          stroke: THEME_COLOR,
          strokeWidth: 1,
          hachureGap: 3
        }
      }
    }
  })
}

export default function Calendar({ data, setTooltipProps }) {
  const cellWidth = 30;
  const cellHeight = 30;
  const THEME_COLOR = "#ffb24c";

  // 准备刻度数据
  const monthData = d3.timeMonths(d3.timeMonth(new Date(data[0].date)), new Date(data[data.length - 1].date));
  const dayData = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];

  // 准备颜色映射数据：最大内容数
  const max_content_count = Math.max(...data.map(d => (d['content_count_boy'] + d['content_count_girl'])));

  // 准备日力图元素数据：初始化绘图数据
  const initCalendarProps = getRectProps({ data, cellWidth, cellHeight, THEME_COLOR, max_content_count });

  const [calendarProps, setCalendarProps] = useState(initCalendarProps);

  // 交互：hover样式
  function changeRectStyle(index) {
    const newCalendarProps = [...initCalendarProps];
    newCalendarProps[index] = {
      ...newCalendarProps[index],
      options: {
        ...newCalendarProps[index].options,
        stroke: '#000',
        strokeWidth: 3,
      }
    }
    setCalendarProps(newCalendarProps);
  }
  return (
    <svg width={GLOBAL.WIDTH+20} height={GLOBAL.HEIGHT} >
      <RoughProvider >
        {
          calendarProps.map((props, index) => {
            if (props) {
              return (
                <Rectangle
                  {...props}
                  onMouseOver={(evt) => {
                    changeRectStyle(index);
                    setTooltipProps(prev => ({
                      ...prev,
                      value: props?.datum,
                      x: evt.pageX,
                      y: evt.pageY,
                      showToolTip: true
                    }))
                  }}
                  onMouseOut={() => {
                    setCalendarProps(initCalendarProps);
                    setTooltipProps(prev => ({
                      ...prev,
                      showToolTip: false
                    }))
                  }}
                ></Rectangle>
              )
            }
          })
        }
        {
          // 日力图刻度
          monthData.map((month, index) => {
            return (<text x={(d3.timeWeek.count(d3.timeYear(month), month) - 27) * cellWidth + cellWidth} y={20}>{month.getMonth() + 1}</text>)
          })
        }
        {
          // 日力图星期刻度
          dayData.map((day, index) => {
            return (<text y={index * cellHeight + cellHeight + 20} x={40} textAnchor='end'>{day}</text>)
          })
        }
      </RoughProvider>
    </svg>
  )
}