import { useEffect, useState, useRef } from 'react';
import { RoughProvider, Circle, Rectangle } from 'react-roughjs';
import * as d3 from 'd3';
import { GLOBAL } from '../global';

function nodesWithLayout({
  data,
  value,
  title,
  width,
  height,
  padding,
  marginLeft,
  marginRight,
  marginTop,
  marginBottom
}) {
  const V = d3.map(data, value);
  const T = d3.map(data, title);
  const I = d3.range(V.length).filter(i => V[i] > 0);
  const root = d3.pack()
    .size([width - marginLeft - marginRight, height - marginTop - marginBottom])
    .padding(padding)
    (d3.hierarchy({ children: I })
      .sum(i => {
        return V[i]
      }));

  return root.leaves().map((node, index) => ({
    ...node,
    text: T[index],
    datum: data[index]
  }));
}


export default function CirclePacking({ data, setTooltipProps, height = GLOBAL.HEIGHT + 100, width = GLOBAL.WIDTH }) {
  const nodes = nodesWithLayout({
    data,
    value: d => d.count,
    title: d => d.word,
    width,
    height,
    padding: 10,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 50,
    marginBottom: 10
  })
  const initCircleProps = nodes.map(node => {
    return {
      ...node,
      diameter: node.r * 2,
      options: {
        fill: d3.interpolateYlOrBr(node.value / nodes[0].value),
        fillWeight: 3, // thicker lines for hachure
        stroke: GLOBAL.THEME_COLOR,
        strokeWidth: 1,
        hachureGap: 3
      }
    }
  })

  const [circleProps, setCircleProps] = useState(initCircleProps);
  const [annotation, setAnnotation] = useState({});
  const [tooltipHeight, setTooltipHeight] = useState(458);
  const tooltipContent = useRef(null);

  const {
    content_count_boy, date, content, content_count_girl, word, count, source
  } = annotation;

  // 交互：hover样式
  function changeStyle(index) {
    const newCircleProps = [...initCircleProps];
    newCircleProps[index] = {
      ...newCircleProps[index],
      options: {
        ...newCircleProps[index].options,
        stroke: '#000',
        strokeWidth: 3,
      }
    }
    setCircleProps(newCircleProps);
  }

  useEffect(()=>{
    changeStyle(1)
    setAnnotation(circleProps[1].datum)
    // const h = tooltipContent.current.getBoundingClientRect().height
    setTooltipHeight(448);
  }, [])

  return (
    <div style={{position: 'relative'}}>
      <svg width={width} height={height} style={{marginRight: -500}}>
        <RoughProvider >
          {
            circleProps.map((props, index) => {
              if (props) {
                return (
                  <>
                    <Circle
                      {...props}
                      onClick={(evt) => {
                        changeStyle(index)
                        setAnnotation(props?.datum)
                        const h = tooltipContent.current.getBoundingClientRect().height
                        setTooltipHeight(h);
                      }}
                    ></Circle>
                  </>
                )
              }
            })
          }
        </RoughProvider>
        {
          circleProps.map((props, index) => {
            if (props) {
              return (
                <>
                  <text
                    {...props}
                    textAnchor={'middle'}
                    dy={5}
                  >
                    {props.text}
                  </text>
                </>
              )
            }
          })
        }
      </svg>
      <svg width={500 + 30} height={(tooltipHeight || 428) + 10}
        style={{
          position: "absolute",
          top: 20 + 'px',
          left: 100 + 'px',
        }}
      >
        <RoughProvider>
          <Rectangle x={5} y={5} width={500 + 15} height={tooltipHeight || 428} options={
            {
              fill: '#fff',
              fillStyle: 'solid'
            }
          }></Rectangle>
        </RoughProvider>
      </svg>
      <div ref={tooltipContent} id="content" style={{
        position: "absolute",
        top: 20,
        left: 100,
        textAlign: 'left',
        padding: '10px 20px',
        width: 490,
      }}>
        {date && <p >* 日期：{date}</p>}
        {!Number.isNaN(content_count_boy + content_count_girl) && <p >* 聊天总数/天：{content_count_boy + content_count_girl}</p>}
        {content && <p >* 聊天内容：{content}</p>}
        {count && <p>* 频次：{count}</p>}
        {source && <p>* 来源：</p>}
        {source && <ul>
          {
            source.map(d => {
              return <li>{d.date}: {d.content}</li>
            })
          }
        </ul>}
      </div>
    </div>

  )
}