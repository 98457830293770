import { GLOBAL } from '../global';
import { RoughProvider, Line } from 'react-roughjs';

export default function Axis({data, scale}) {
  const HEIGHT = 70;
 
  return(
    <svg width={GLOBAL.WIDTH} height={HEIGHT} >
      <RoughProvider >
        <Line x1={0} y1={HEIGHT/2} x2={GLOBAL.WIDTH} y2={HEIGHT/2}></Line>
        {
          data.map((datum, index)=>{
            const { step, text } = datum;
            return (
              <g>
                <Line x1={scale(step)} y1={HEIGHT/2} x2={scale(step)} y2={HEIGHT/2 + 10}></Line>
                <text x={scale(step)} y={HEIGHT/2+30} fontSize={12}>{text}</text>
              </g>
            )
          })
        }
      </RoughProvider>
    </svg>
  )
}