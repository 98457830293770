import './App.css';
import { useState } from 'react';
import Calendar from './components/calendar';
import data from './data/csvjson.json';
import wordCount from './data/wordCount.json';
import Tooltip from './components/tooltip';
import Timeline from './components/Timeline';
import BarChart from './components/barChart';
import bg from './img/bg.png';
import letter from './img/letter.png';
import CirclePacking from './components/circlePacking';

function App() {
  const [tooltipProps, setTooltipProps] = useState({
    x: -1,
    y: -1,
    width: 400,
    height: 170,
    showToolTip: false,
    title: '',
    value: '',
  });
  return (
    <div className="App">
      <h1 className="title">Hello～ 酥小梨，猜猜今天是什么日子？</h1>
      <img src={bg} id="bg" />
      <h1 className="desc" >恭喜你！你猜的没错：祝你「生日快乐」！</h1>
      <h2 className="desc" >那么接下来...是的。聪明的你又猜中了！</h2>
      <h3 className="desc" >一份关于微信聊天记录的、2021年度的、“甜蜜”的可视化报告即将出炉。</h3>
      <h3 className="desc" >嘟嘟嘟...车门已经焊死，准备上车了！</h3>
      <h3 className="desc" >准备好了吗？我们出发啦</h3>
      <h3 className="desc" >请查收❤️</h3>
      <img src={letter} style={{ width: '400px' }} />
      <br></br>
      <br></br>
      <h3 className="desc" >那么首先，想问一些问题...</h3>
      <br></br>
      <h1 className="desc" >我们认识多久了？⬇️</h1>
      <br></br>
      <Calendar data={data} setTooltipProps={setTooltipProps} />
      <h3 className="desc" >7、8、9、10...11、12, 再...1、2。</h3>
      <h3 className="desc" >认识第8个月了，超过半年了哦~</h3>
      <h2 className="desc" >聊天习惯如何？都聊了什么事？</h2>
      <ul style={{ width: 800, margin: '0 auto', textAlign: 'left' }} className="contentUl">
        <li>频率趋势：我们的聊天时期被分为了两个时间段：以9月份为分割线的前期和后期。</li>
        <li>为什么9月份的聊天是断断续续的？答案大概是因为我们“粘”在了一起😳，能经常见面聊天呀。</li>
        <li>后半部分的聊天逐渐多了起来，为什么呢？原来是我们会经常分享公司和实验室的趣事😝，以及即将见面的兴奋心情😊。</li>
        <li>前半部分话虽然腼腆话少，但是...我们一起经历了很多“重要的事”！</li>
      </ul>

      <h3 className="desc" ></h3>
      <br></br>
      <h1 className="desc" >一起经历了什么“重要的事”呢？⬇️</h1>
      <h3 className="desc" >和我们的熟悉程度有关吗？</h3>
      <br></br>
      <ul style={{ width: 800, margin: '0 auto', textAlign: 'left' }} className="contentUl">
        <li>黄色横线中间的文字表示“重要的事”</li>
        <li>两个小人头的距离表示熟悉程度</li>
        <li>黄色横线表示“每次聊多久”的时间跨度</li>
      </ul>
      <Tooltip {...tooltipProps}></Tooltip>
      <Timeline data={data} setTooltipProps={setTooltipProps} />
      <br></br>
      <br></br>
      <br></br>
      <h3 className="desc" >我们经历了好多“重要的事”，其实这些都叫做“美好的回忆”。</h3>
      <h3 className="desc" >他们独一无二🪐✨，很难忘记~</h3>
      <h3 className="desc" >等一下...</h3>
      <h1 className="desc" >我好像发现了你“追”我的证据？！⬇️</h1>
      <ul style={{ width: 800, margin: '0 auto', textAlign: 'left' }} className="contentUl">
        <li>ps：蓝色表示你的聊天记录数量；粉色表示我滴</li>
        <li>看看这个前期的聊天数量，你总是会比我多说一点话哦，哈哈</li>
        <li>后期我们逐渐持平了😏</li>
        <li>这，，，从这个趋势来看，我们的聊天数确实越来越多，但好像很“起伏不定”啊</li>
        <li>最高峰的几个点：
          <ul>
            <li>
              2021/8/25: 我们第一次分开，你送我去机场；然后聊起了一些工作生活的琐事
            </li>
            <li>
              2021/10/7: 我们刚享受玩国庆假期🇨🇳，我回去了北京，聊的都是寄快递的事情哈哈
            </li>
            <li>
              2021/11/6: 好像也没有发生什么特别的事，你分享了一首很酷的歌，我分享了好看的🍁...
            </li>
            <li>
              2021/11/13: 我的电脑进水了之后，怒写中期报告！大概聊了一些可视化的知识🤦‍♀️...
            </li>
            <li>
              2021/12/13: 刚结束美好的环球影城之旅，发送了很多搞笑的照片😆...
            </li>
          </ul>
        </li>
      </ul>

      <BarChart data={data}></BarChart>
      <h3 className="desc" >原来“干货/正经事”占据了这么多聊天记录：知识点、照片、快递信息...</h3>
      <h3 className="desc" >等等，记录数最多并不等于最常聊这个东西？</h3>
      <h3 className="desc" >那么...</h3>
      <h1 className="desc" >我们都喜欢聊什么内容呢？⬇️</h1>
      <CirclePacking data={wordCount}
        height={670}
        setTooltipProps={setTooltipProps}>
      </CirclePacking>
      <h3 className="desc" >“哥哥”...发现了一些不得了的事情...</h3>
      <h3 className="desc" >想念哥哥和妹妹都是很重要的事‼️ 聊天记录骗不了人哦😏</h3>
      <ul style={{ width: 800, margin: '0 auto', textAlign: 'left' }} className="contentUl">
        <li>“分享”虽然不是聊天的关键词，但却是我们日常的最高频行为~!</li>
        <li>然后就是“约走路”啦😳</li>
        <li>“视频”指的是你喜欢和我分享一些搞笑视频；“pr”也是你经常喜欢和我提及的哦😆</li>
      </ul>
      <br></br>
      <br></br>
      <br></br>
      <h2 className="desc" >我们之间的关键词很“暖心”🧡，并且还...挺“卷”！🥸</h2>
      <h3 className="desc" >看到这里，也快到尾声啦...</h3>
      <h3 className="desc" >祝愿新的一年里，酥小梨🍐能够“心想事成”</h3>
      <h3 className="desc" >天天向上！👆</h3>
      <br></br>
      <img src={letter} style={{ width: '400px' }} />
    </div>
  );
}

export default App;
