import { GLOBAL } from '../global';
import { RoughProvider, Line } from 'react-roughjs';

export default function Grid({data, scale, height}) {
 
  return(
    <svg width={GLOBAL.WIDTH} height={height} >
      <RoughProvider >
        {
          data.map((datum, index)=>{
            const { step } = datum;
            return (
              <g>
                <Line x1={scale(step)} y1={0} x2={scale(step)} y2={height} 
                options={{
                  bowing: 0,
                  strokeLineDash: [5, 15],
                  stroke: '#eee'
                }}></Line>
              </g>
            )
          })
        }
      </RoughProvider>
    </svg>
  )
}