import { useEffect, useRef, useState } from "react";
import { Rectangle, RoughProvider } from "react-roughjs";

export default function Tooltip({
  x, y, width, height, showToolTip, title, value = {}
}) {
  const tooltipContent = useRef(null);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  let h = 0;
  useEffect(()=>{
    h = tooltipContent.current.getBoundingClientRect().height
    setTooltipHeight(h);
  }, [value])
  const {
    content_count_boy, date, content, content_count_girl, word, count, source
  } = value;
  return (
    <div style={{
      width: width + 'px',
      position: "absolute",
      top: y + 10 + 'px',
      left: x + 10 + 'px',
      visibility: showToolTip ? 'visible' : 'hidden'
    }}>
      <svg width={width + 10} height={tooltipHeight + 10}
        style={{
          position: "absolute",
          top: 0 + 'px',
          left: 0 + 'px',
        }}
      >
        <RoughProvider>
          <Rectangle x={5} y={5} width={width} height={tooltipHeight} options={
            {
              fill: '#fff',
              fillStyle: 'solid'
            }
          }></Rectangle>
        </RoughProvider>
      </svg>
      <div ref={tooltipContent} style={{
        position: "absolute",
        textAlign: 'left',
        padding: '10px 20px'
      }}>
        {date && <p >* 日期：{date}</p>}
        {!Number.isNaN(content_count_boy + content_count_girl) && <p >* 聊天总数/天：{content_count_boy + content_count_girl}</p>}
        {content && <p >* 聊天内容：{content}</p>}
        {count && <p>* 频次：{count}</p>}
        {source && <p>* 来源：</p>}
        {source && <ul>
          {
            source.map(d => {
              return <li>{d.date}: {d.content}</li>
            })
          }
          </ul>}
      </div>
    </div>
  )
}
